import store from '@/store'
import notifier from '@/utils/notifier'
import { redirectToInitialWorkspace } from './_helpers'
import aiTaskBuilder from './ai-task-builder'
import bringParticipants from './bring-participants'
import filterSets from './filter-sets'
import finance from './finance'
import messages from './messages'
import participantGroups from './participant-groups'
import projects from './projects'
import settings from './settings'
import studies from './studies'
import team from './team'

export default [
  // All child routes in 'researcher/workspaces/*' will pass through this beforeEnter hook
  {
    path: '',
    component: () => import('@/views/researcher/workspaces/Workspaces.vue'),
    async beforeEnter(to, from, next) {
      try {
        const activeWorkspaceId =
          store.getters['researcher/workspaces/workspace'].id

        if (
          to.params.workspaceId &&
          to.params.workspaceId !== activeWorkspaceId
        ) {
          await store.dispatch(
            'researcher/workspaces/fetchWorkspace',
            to.params.workspaceId
          )
        }
        if (from.meta.noRefetch) {
          // prevents refetching of data on redirect from workspaces.home => workspaces.projects
          return next()
        }
        next()
      } catch (_error) {
        next()
      }
    },
    children: [
      // keep as a redirect to the home page in case researchers have old url
      {
        path: 'researcher/get-started',
        name: 'get-started',
        redirect: { name: 'researcher.old.home' },
      },
      {
        path: 'researcher/home',
        name: 'researcher.old.home',
        beforeEnter(to, from, next) {
          const workspaceId =
            store.getters['researcher/workspaces/workspace'].id
          next({ name: 'workspaces.workspace.home', params: { workspaceId } })
        },
      },
      {
        path: 'researcher/workspaces/:workspaceId/home',
        name: 'workspaces.workspace.home',
        component: () => import('@/views/researcher/HomePage.vue'),
      },
      {
        path: 'researcher/workspaces',
        name: 'workspaces.home',
        beforeEnter(to, from, next) {
          // if there's already an active workspace in state, go to that
          const currentWorkspaceId =
            store.getters['researcher/workspaces/workspace']?.id
          if (currentWorkspaceId) {
            return next({
              name: 'workspaces.workspace.home',
              params: { workspaceId: currentWorkspaceId },
              replace: true,
            })
          }

          // else, if the user has a current project (this is set on the BE after fetching a study or project)
          const currentProjectId = store.getters['auth/user'].current_project_id
          if (currentProjectId) {
            const { status } = from.meta
            // redirect legacy new study to workspaces new study
            from.meta.noRefetch = true
            if (status === 'new') {
              return next({
                name: 'workspaces.studies.new',
                params: {
                  projectId: currentProjectId,
                },
                replace: true,
              })
            }
            // the user has a last viewed project. Redirect to that
            return next({
              name: 'workspaces.project',
              params: {
                projectId: currentProjectId,
                status,
              },
              replace: true,
            })
          }

          // else redirect to the initial workspace
          redirectToInitialWorkspace(next)
        },
      },
      {
        path: '/researcher/workspaces/:workspaceId/cost-calculator',
        name: 'workspaces.calculator',
        component: () => import('@/views/researcher/CostCalculator.vue'),
      },

      // child routes
      ...studies,
      ...projects,
      ...filterSets,
      ...participantGroups,
      ...bringParticipants,
      ...aiTaskBuilder,
      ...finance,
      ...team,
      ...settings,
      ...messages,
    ],
  },

  {
    path: '/researcher/workspaces',
    name: 'workspaces.none',
    component: () => import('@/views/researcher/workspaces/None.vue'),
  },

  {
    path: '/researcher/invitations/:token',
    name: 'researcher.invitations',
    async beforeEnter(to, from, next) {
      try {
        const workspaceId = await store.dispatch(
          'researcher/workspaces/handleInvitation',
          to.params.token
        )
        next({ name: 'workspaces.projects', params: { workspaceId } })
        notifier.success('You have successfully joined this workspace')
      } catch (_error) {
        next({ name: 'home' })
      }
    },
  },

  // legacy redirects
  {
    path: '/researcher/studies/:status(new|draft|active|scheduled|completed)',
    name: 'researcher.studies.legacy.status',
    beforeEnter(to, from) {
      from.meta.status = to.params.status
      return {
        name: 'workspaces.home',
      }
    },
  },
  {
    path: '/researcher/studies/:pathMatch(.*)',
    redirect: to => {
      return {
        path: `researcher/workspaces/studies/${to.params.pathMatch}`,
      }
    },
  },
  {
    path: '/researcher/topup',
    redirect: 'researcher/workspaces/finance',
  },
  {
    path: '/researcher/account/billing',
    redirect: 'researcher/workspaces/finance',
  },
]
