import type { RouteRecordRaw, RouteLocationRaw } from 'vue-router'
import { featureIsEnabled } from '@/integrations/launchDarkly'
import { FLAG_SEA_WORKSPACE_MESSAGING } from '@/integrations/launchDarkly/active-flags'
import store from '@/store'

const Messages = () => import('@/views/messages/Messages.vue')

async function sharedNavigationGuard(): Promise<RouteLocationRaw | null> {
  if (store.getters['auth/isBanned']) {
    // don't allow banned users to access messaging due to abuse
    return { name: 'home' }
  }
  try {
    await store.state.messages.loginPromise
  } catch (_error) {
    // firebase login failed
    return { name: 'error.500' }
  }
  return null
}

function getWorkspaceId() {
  return (
    store.getters['researcher/workspaces/workspaceId'] ??
    store.getters['researcher/workspaces/workspaces']?.[0].id
  )
}

export default [
  {
    // legacy redirects ------------------------------------------------>
    path: 'messages',
    redirect: { name: 'messages.inbox' },
  },

  {
    path: 'messages/inbox',
    name: 'messages.inbox',
    component: Messages,
    beforeEnter(_to, _from, next) {
      const workspaceId = getWorkspaceId()
      return next({
        name: 'workspaces.messages',
        params: { workspaceId },
        query: { view: 'workspace', inbox: 'primary' },
      })
    },
  },

  {
    path: 'messages/archive',
    name: 'messages.archive',
    component: Messages,
    beforeEnter(_to, _from, next) {
      const workspaceId = getWorkspaceId()
      return next({
        name: 'workspaces.messages',
        params: { workspaceId },
        query: { view: 'workspace', inbox: 'archive' },
      })
    },
  },
  {
    path: 'messages/:channel',
    name: 'messages.channel',
    component: Messages,
    beforeEnter(to, _from, next) {
      const workspaceId = getWorkspaceId()
      const channel = to.params['channel']
      return next({
        name: 'workspaces.messages.channel',
        params: { workspaceId, channel },
      })
    },
  },
  // ------------------------------------------------------------------>

  // researcher workspace messaging
  {
    path: 'researcher/workspaces/:workspaceId/messages',
    name: 'workspaces.messages',
    component: Messages,
    async beforeEnter(to, _from, next) {
      const redirect = await sharedNavigationGuard()
      if (redirect) {
        return next(redirect)
      }

      if (
        !featureIsEnabled(FLAG_SEA_WORKSPACE_MESSAGING) &&
        to.query['view'] === 'workspace'
      ) {
        return next({
          name: 'workspaces.messages',
          params: { workspaceId: to.params['workspaceId'] },
          query: { view: 'personal', inbox: to.query['inbox'] },
        })
      }
      next()
    },
  },
  // channel
  {
    path: 'researcher/workspaces/:workspaceId/messages/:channel',
    name: 'workspaces.messages.channel',
    component: Messages,
    async beforeEnter(_to, _from, next) {
      const redirect = await sharedNavigationGuard()
      if (redirect) {
        return next(redirect)
      }

      next()
    },
  },

  // workspace messaging home - will be workspace inbox with flag on,
  // or personal inbox with flag off
  {
    path: 'researcher/workspaces/:workspaceId/messages',
    name: 'workspaces.messages.home',
    component: Messages,
    beforeEnter(to, _from, next) {
      if (!featureIsEnabled(FLAG_SEA_WORKSPACE_MESSAGING)) {
        return next({
          name: 'workspaces.messages',
          params: { workspaceId: to.params['workspaceId'] },
          query: { view: 'personal', inbox: 'primary' },
        })
      }
      return next({
        name: 'workspaces.messages',
        params: { workspaceId: to.params['workspaceId'] },
        query: { view: 'workspace', inbox: 'primary' },
      })
    },
  },
] satisfies RouteRecordRaw[]
