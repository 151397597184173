import type {
  DraftState as AITaskBuilderDraftState,
  Nullable,
} from '@/store/modules/researcher/ai-task-builder'
import { DecryptionError, decrypt, encrypt } from '@/utils/encryption'
import { sessionStorageGetItem, sessionStorageSetItem } from '@/utils/storage'

type SerializedDraft = Nullable<{
  batchName: string
  batchId: string
  taskDetails: Nullable<{
    task_name: string
    task_introduction: string
    task_steps: string
  }>
  datasetFile:
    | File
    | {
        content: string
        name: string
        type: string
      }
  datasetId: string
  datasetPreview: string
  datasetHasPredeterminedGroupingId: boolean
  instructions: string
}>

type SaveableDraft = Partial<AITaskBuilderDraftState>
export class AITaskBuilderDraftStore {
  private readonly dbName = 'aitb-draft-store'
  private readonly objectStoreName = 'drafts'
  private readonly version = 1
  private readonly storageLabel = 'prolific-aitb-dsid'

  private openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.version)

      request.onerror = () => reject(request.error)
      request.onsuccess = () => resolve(request.result)

      request.onupgradeneeded = event => {
        const db = (event.target as IDBOpenDBRequest).result
        if (!db.objectStoreNames.contains(this.objectStoreName)) {
          db.createObjectStore(this.objectStoreName)
        }
      }
    })
  }

  private async getStore(
    mode: IDBTransactionMode = 'readonly'
  ): Promise<IDBObjectStore> {
    const db = await this.openDB()
    const transaction = db.transaction(this.objectStoreName, mode)
    return transaction.objectStore(this.objectStoreName)
  }

  private getEncryptionKey(): string {
    const existingKey = sessionStorageGetItem(this.storageLabel)
    if (existingKey) {
      return existingKey as string
    }

    const key = crypto.randomUUID()
    sessionStorageSetItem(this.storageLabel, key)
    return key
  }

  private async getEncryptedDraft(id: string): Promise<SerializedDraft | null> {
    try {
      const key = this.getEncryptionKey()
      const store = await this.getStore()

      const result = await new Promise<SerializedDraft | undefined>(
        (resolve, reject) => {
          const request = store.get(id)
          request.onerror = () => reject(request.error)
          request.onsuccess = () => resolve(request.result)
        }
      )

      if (!result) {
        return null
      }

      // Try and decrypt the first string value in the serialized draft.
      // If descryption fails then the draft is no longer viable and
      // we can delete it and return null.
      await decrypt(
        key,
        (Object.values(result).find(value => typeof value === 'string') ??
          '') as string
      )
      return result
    } catch (error) {
      if (error instanceof DecryptionError) {
        await this.delete(id)
        sessionStorageSetItem(this.storageLabel, '')
        return null
      }

      throw error
    }
  }

  /**
   * Creates or updates an AI Task Build Draft record in IndexedDB.
   * This method will overwrite any provided properties of the draft state being saved,
   * but leave existing properties on the record (if any) intact.
   *
   * To create a new record entirely, first call
   * ```typescript
   * AITaskBuilderDraftStore.delete(id)
   * ```
   *
   * @param id - The ID of the record to create or update
   * @param draft - The AI Task Builder Draft state to be saved
   * @returns Promise<void>
   */
  async set(id: string, draft: SaveableDraft): Promise<void> {
    const existingItem = await this.getEncryptedDraft(id)
    const key = this.getEncryptionKey()

    /**
     * This looks a bit gnarly, but it's just setting the values
     * on the record to be saved using the following strategy:
     *
     * If the draft being saved has a value for a property:
     *  - encrypt the value and set it on the saveable record
     * Else if there's an existing record which has a value for the property:
     *  - set the already encrypted value from the existing record on the saveable record
     * Else there's no value for property on the draft, and no encrypted value for the property on the existing record
     *  - set the value to null on the saveable record
     */
    const saveableDraft: SerializedDraft = {
      batchId: draft.batchId
        ? await encrypt(key, draft.batchId)
        : (existingItem?.batchId ?? null),
      batchName: draft.batchName
        ? await encrypt(key, draft.batchName)
        : (existingItem?.batchName ?? null),
      taskDetails: {
        task_name: draft.taskDetails?.task_name
          ? await encrypt(key, draft.taskDetails?.task_name)
          : (existingItem?.taskDetails?.task_name ?? null),
        task_introduction: draft.taskDetails?.task_introduction
          ? await encrypt(key, draft.taskDetails?.task_introduction)
          : (existingItem?.taskDetails?.task_introduction ?? null),
        task_steps: draft.taskDetails?.task_steps
          ? await encrypt(key, draft.taskDetails?.task_steps)
          : (existingItem?.taskDetails?.task_steps ?? null),
      },
      datasetFile: draft.datasetFile
        ? new File(
            [await encrypt(key, await draft.datasetFile.text())],
            draft.datasetFile.name,
            { type: draft.datasetFile.type }
          )
        : (existingItem?.datasetFile ?? null),
      datasetId: draft.datasetId ? await encrypt(key, draft.datasetId) : null,
      datasetPreview: draft.datasetPreview
        ? await encrypt(key, JSON.stringify(draft.datasetPreview))
        : (existingItem?.datasetPreview ?? null),
      datasetHasPredeterminedGroupingId:
        draft.datasetHasPredeterminedGroupingId ??
        existingItem?.datasetHasPredeterminedGroupingId ??
        null,
      instructions: draft.instructions
        ? await encrypt(key, JSON.stringify(draft.instructions))
        : (existingItem?.instructions ?? null),
    }

    return new Promise((resolve, reject) => {
      this.getStore('readwrite')
        .then(store => {
          const request = store.put(saveableDraft, id)
          request.onerror = () => reject(request.error)
          request.onsuccess = () => resolve()
        })
        .catch(reject)
    })
  }

  async get(id: string): Promise<AITaskBuilderDraftState | null> {
    try {
      const encryptedDraft = await this.getEncryptedDraft(id)
      const key = this.getEncryptionKey()

      if (!encryptedDraft) {
        return null
      }

      const decryptedDraft: AITaskBuilderDraftState = {
        batchId: encryptedDraft.batchId
          ? await decrypt(key, encryptedDraft.batchId)
          : null,
        batchName: encryptedDraft.batchName
          ? await decrypt(key, encryptedDraft.batchName)
          : null,
        taskDetails: {
          task_name: encryptedDraft.taskDetails?.task_name
            ? await decrypt(key, encryptedDraft.taskDetails.task_name)
            : null,
          task_introduction: encryptedDraft.taskDetails?.task_introduction
            ? await decrypt(key, encryptedDraft.taskDetails.task_introduction)
            : null,
          task_steps: encryptedDraft.taskDetails?.task_steps
            ? await decrypt(key, encryptedDraft.taskDetails.task_steps)
            : null,
        },
        datasetId: encryptedDraft.datasetId
          ? await decrypt(key, encryptedDraft.datasetId)
          : null,
        datasetFile: null,
        datasetPreview: encryptedDraft.datasetPreview
          ? JSON.parse(await decrypt(key, encryptedDraft.datasetPreview))
          : null,
        datasetHasPredeterminedGroupingId:
          encryptedDraft.datasetHasPredeterminedGroupingId ?? null,
        instructions: encryptedDraft.instructions
          ? JSON.parse(await decrypt(key, encryptedDraft.instructions))
          : null,
      }

      if (encryptedDraft.datasetFile) {
        const datasetFileContents =
          encryptedDraft.datasetFile instanceof File
            ? await encryptedDraft.datasetFile.text()
            : encryptedDraft.datasetFile?.content

        decryptedDraft.datasetFile = new File(
          [await decrypt(key, datasetFileContents)],
          encryptedDraft.datasetFile?.name,
          { type: encryptedDraft.datasetFile?.type }
        )
      }

      return decryptedDraft
    } catch (error) {
      throw new Error(
        `Failed to retrieve draft: ${
          error instanceof Error ? error.message : error
        }`
      )
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const store = await this.getStore('readwrite')
      await new Promise<void>((resolve, reject) => {
        const request = store.delete(id)
        request.onerror = () => reject(request.error)
        request.onsuccess = () => resolve()
      })
    } catch (error) {
      throw new Error(
        `Failed to delete draft: ${
          error instanceof Error ? error.message : error
        }`
      )
    }
  }

  async getAllKeys(): Promise<string[]> {
    try {
      const store = await this.getStore()
      return new Promise<string[]>((resolve, reject) => {
        const request = store.getAllKeys()
        request.onerror = () => reject(request.error)
        request.onsuccess = () =>
          resolve(Array.from(request.result as string[]))
      })
    } catch (error) {
      throw new Error(
        `Failed to retrieve draft keys: ${
          error instanceof Error ? error.message : error
        }`
      )
    }
  }
}
