import type { MESSAGE_CATEGORIES } from './constants'

/**
 * An interface representing a direct participant thread. The type is used to store
 * and manipulate data related to a participant's threads in a one-on-one conversation
 * context.
 *
 * @property participantId - The unique identifier of the participant.
 * @property channelId - The unique identifier of the channel.
 * @property unreadCount - The number of unread messages in the channel.
 * @property datetimeUpdated - The date and time when the channel was last updated.
 */
export type Thread = {
  participantId: string
  spaceId?: string
  channelId: string
  unreadCount: number
  datetimeUpdated?: Date
}

/**
 * A type that represents a group of threads.
 */
export type Group = {
  /**
   * The key of the group. Linked to the data model.
   */
  key: string

  /**
   * The number of unread messages in the group.
   */
  unreadCount: number

  /**
   * An array of Thread objects.
   */
  threads: Thread[]
}

export type InboxGroup = Record<'personal' | 'workspace', Group[]>

/**
 * Enumeration representing the possible document types.
 * @readonly
 * @enum {string}
 */
export enum DocumentType {
  Message = 'message',
  Event = 'event',
}

/**
 * A base interface representing the common properties for all document types.
 *
 * @interface BaseDocument
 * @property {string} id - The unique identifier of the document.
 * @property {Date} date - The date when the document was created.
 * @property {Date} datetime - The date and time when the document was created.
 * @property {DocumentType} type - The type of the document (e.g., message or event).
 */
export interface BaseDocument {
  id: string
  date: Date
  datetime: Date
  type: DocumentType
  space: 'personal' | 'workspace'
}

/**
 * An interface representing a message document, which extends the BaseDocument interface.
 * The message document is used to store and manipulate data related to messages in a conversation.
 *
 * @interface Message
 * @extends {BaseDocument}
 * @property {string} body - The content of the message.
 * @property {string | null} userName - The name of user who sent the message (will be null for participants).
 * @property {'researcher' | 'participant'} userType - The type of user who sent the message.
 */
export interface Message extends BaseDocument {
  body: string
  userType: 'researcher' | 'participant'
  userName: string | null
}

/**
 * An interface representing an event in the application, which extends the BaseDocument interface.
 * The event is used to store and manipulate data related to events in the application.
 *
 * @interface Event
 * @extends {BaseDocument}
 * @property {number} [amount] - Optional number representing the amount of the event.
 * @property {string} [currency] - Optional string representing the currency of the event.
 * @property {string} kind - Required string specifying the type of event.
 */
export interface Event extends BaseDocument {
  amount?: number
  currency?: string
  kind: string
}

/**
 * An array of objects, where each object contains a date of type Date and an array of messages
 * that can be either a Message or Event type. Used to group messages and events by their date.
 *
 * @typedef {Object[]} MessagesGroupedByDate
 * @property {Date} date - The date to group messages and events by.
 * @property {(Message | Event)[]} messages - An array of messages that can be either a Message or Event type.
 */
export type MessagesGroupedByDate = {
  date: Date
  messages: (Message | Event)[]
}[]

/**
 * A type representing the default view for the chat interface when no channel is selected.
 *
 * @property {string} name - The name of the view, set to 'root'.
 */
type RootView = { name: 'root' }

/**
 * A type representing the view when a channel is selected in the chat interface.
 *
 * @property {string} name - The name of the view, set to 'channel'.
 * @property {string} id - The channelId from the Channel type.
 */
type ChannelView = {
  name: 'channel'
  id: Thread['channelId']
}

/**
 * A type representing the view for creating a new message in the chat interface.
 *
 * @property {string} name - The name of the view, set to 'new-message'.
 */
type NewMessageView = { name: 'new-message' }

type GroupView = { name: 'group'; key: string }

/**
 * A union of `RootView`, `ChannelView`, and `NewMessageView`.
 * Used to describe the current active view in the chat interface.
 *
 * @typedef {RootView | ChannelView | NewMessageView} View
 */
export type View = RootView | ChannelView | NewMessageView | GroupView

export type MessageCategory = (typeof MESSAGE_CATEGORIES)[number]

export type UpdateStudy = {
  unread_count: number
  category_counts?: Record<string, number>
}
