import { createRouter as newCreateRouter, createWebHistory } from 'vue-router'
import AuthenticatedLayout from '@/components/layout/Authenticated.vue'
import store from '@/store'
import { beforeEach, afterEach } from './hooks'
import routes from './routes'
import participant from './routes/participant'
import researcher from './routes/researcher'

const createRouter = () =>
  newCreateRouter({
    history: createWebHistory(),
    routes,
  })

const router = createRouter()

if (import.meta.env.NODE_ENV !== 'test') {
  router.beforeEach(beforeEach)
  router.afterEach(afterEach)
}

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

// used in unit tests & here
export function getUserTypeSpecificRoutes(userType) {
  return [
    {
      path: '/',
      component: AuthenticatedLayout,
      props: { userType },
      children: userType === 'researcher' ? researcher : participant,

      async beforeEnter(to, from, next) {
        if (userType === 'researcher') {
          const globalData = [
            // this is used for the workspace switcher in the nav bar
            store.dispatch(
              'researcher/workspaces/fetchWorkspaces',
              '?limit=50'
            ),
            // this is used for tracking to differentiate a "new" researcher from an "existing" one
            store.dispatch('researcher/studies/fetchHasStudies'),
          ]

          // this is needed because we have flags on non-workspace routes that use workspace-based feature flags (e.g. PSG_870_NEW_AUDIENCE_SELECTION)
          // once this flag is removed, we can re-visit (we will also need to update any other researcher routes that assume a workspace is set e.g. referrals)
          if (!to.name.startsWith('workspaces')) {
            await Promise.all([
              handleSetWorkspaceForResearcherRoutes(),
              ...globalData,
            ])
          } else {
            await Promise.all(globalData)
          }
        }

        next()
      },
    },
  ]
}

export function addUserTypeSpecificRoutes(userType) {
  getUserTypeSpecificRoutes(userType).forEach(item => router.addRoute(item))
}

export async function handleSetWorkspaceForResearcherRoutes() {
  // if the user has come from a workspace route, we know what their active workspace is
  const activeWorkspaceId = store.getters['researcher/workspaces/workspace']?.id
  if (activeWorkspaceId) {
    // no need to fetch a single workspace its already in state
    return
  }

  // if they have done some activity (e.g. viewed a study or project) current_project_id will be set
  const currentProjectId = store.getters['auth/user']?.current_project_id
  if (currentProjectId) {
    await store.dispatch(
      'researcher/workspaces/fetchCurrentWorkspaceByProjectId',
      currentProjectId
    )
    return
  }

  // else, we'll pick the last workspace from their list
  const workspaces = store.getters['researcher/workspaces/workspaces'] ?? []
  const latestWorkspaceId = workspaces[workspaces.length - 1]?.id
  if (latestWorkspaceId) {
    await store.dispatch(
      'researcher/workspaces/fetchWorkspace',
      latestWorkspaceId
    )
  }
}

export default router
