import type {
  Message,
  Event,
  BaseDocument,
  MessagesGroupedByDate,
  Group,
  Thread,
  InboxGroup,
} from './types'
import { DocumentType } from './types'

/**
 * Given a bunch of Firestore documents, group them by a given
 * attribute into a Group that can be used to render
 * the aggregated data.
 */
export const transformFirebaseStudyChannelsByAttribute = (
  attribute: string,
  channels: unknown
): InboxGroup => {
  if (!channels || !Array.isArray(channels)) {
    return {
      workspace: [],
      personal: [],
    }
  }

  const categoryIndex: Record<
    'workspace' | 'personal',
    Record<string, Group>
  > = {
    workspace: {},
    personal: {},
  }

  channels.forEach(channelData => {
    if (
      !channelData ||
      typeof channelData !== 'object' ||
      !('participant_id' in channelData) ||
      !('unread_count' in channelData) ||
      !(attribute in channelData) ||
      !('id' in channelData) ||
      ('messages' in channelData &&
        !Object.values(channelData.messages).some(isMessage))
    ) {
      return
    }

    const spaceId = channelData.space_id
    const inboxType = spaceId ? 'workspace' : 'personal'

    const channel: Thread = {
      participantId: channelData.participant_id,
      unreadCount: channelData.unread_count,
      channelId: channelData.id,
      ...(spaceId ? { spaceId } : {}),
    }

    if (
      typeof channelData[attribute] === 'object' &&
      channelData[attribute] != null
    ) {
      for (const [attributeValue, data] of Object.entries(
        channelData[attribute]
      )) {
        const count = Number(data)
        const existingCategory = categoryIndex[inboxType][attributeValue]

        if (existingCategory) {
          existingCategory.unreadCount += count
          existingCategory.threads.push(channel)
        } else {
          categoryIndex[inboxType][attributeValue] = {
            key: attributeValue,
            unreadCount: count,
            threads: [channel],
          }
        }
      }
    } else {
      const existingCategory = categoryIndex[inboxType][channelData[attribute]]
      if (existingCategory) {
        existingCategory.unreadCount += channelData.unread_count
        existingCategory.threads.push(channel)
      } else {
        categoryIndex[inboxType][channelData[attribute]] = {
          key: channelData[attribute],
          unreadCount: channelData.unread_count,
          threads: [channel],
        }
      }
    }
  })

  return {
    workspace: Object.values(categoryIndex.workspace),
    personal: Object.values(categoryIndex.personal),
  }
}

/**
 * Transforms an array of Firebase message objects into an array of messages grouped by date.
 *
 * @param messages - An array of Firebase message objects to transform.
 * @returns An array of messages grouped by date, with each element containing a `date` property
 *          (representing the date of the messages) and a `messages` property (an array of messages
 *          that share the same date).
 */
export const transformFirebaseMessages = (
  messages: unknown
): MessagesGroupedByDate => {
  if (messages && Array.isArray(messages)) {
    const messagesByDate: MessagesGroupedByDate = []
    for (const message of messages) {
      const requiredKeys = getRequiredKeys(message)
      if (
        !(
          Array.isArray(requiredKeys) &&
          requiredKeys.every(
            key => key in message || (message?.data && key in message.data)
          ) &&
          !!message.datetime_created
        )
      ) {
        continue
      }

      const date = message.datetime_created.toDate()
      const baseMessage = {
        id: message.id,
        date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
        datetime: date,
        type: message.type,
        space: message.senderSpace?.space_type || 'personal',
      }

      let transformedMessage: Message | Event | undefined

      if (message.type === DocumentType.Message) {
        const userType = message.sender.user_type
        transformedMessage = {
          ...baseMessage,
          body: message.body,
          userType,
          userName: userType === 'researcher' ? message.sender.user_name : null,
        } as Message
      }

      if (message.type === DocumentType.Event) {
        transformedMessage = {
          ...baseMessage,
          kind: message.kind,
        } as Event

        if ('amount' in message.data && 'currency' in message.data) {
          transformedMessage.amount = message.data.amount
          transformedMessage.currency = message.data.currency
        }
      }

      if (!transformedMessage) {
        continue
      }

      const existingDate = messagesByDate.find(
        message => message.date.getTime() === transformedMessage!.date.getTime()
      )

      if (existingDate) {
        existingDate.messages.push(transformedMessage)
      } else {
        messagesByDate.push({
          date: transformedMessage.date,
          messages: [transformedMessage],
        })
      }
    }

    return messagesByDate
  }

  return []
}

/**
 * Given a Firestore document, decide if the document is a Message
 * or not
 */
const isMessage = (obj: unknown): obj is Message => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'type' in obj &&
    (obj as BaseDocument).type === DocumentType.Message
  )
}

/**
 * Get an array of required keys for a message object of a given type.
 *
 * @param message - The message object to check.
 * @returns An array of string keys that are required for the given message type,
 *          or undefined if the input is not an object or the message type is not recognised.
 */
const getRequiredKeys = (message: unknown) => {
  if (typeof message !== 'object') {
    return
  }

  const messageType = (message as { type: string }).type

  if (messageType === DocumentType.Message) {
    return ['body', 'datetime_created', 'sender']
  }

  if (messageType === DocumentType.Event) {
    return ['kind', 'datetime_created', 'sender']
  }

  return
}
